@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;

$c-abbey: #57585B;
$c-concrete-solid: #F2F2F2;  

$transition: all .4s ease;  

// Practical info section
.iconSlider {
  @include mixin.marginTopBottom();
  min-height: 214px;

  &.withLead {
    min-height: 364px;
  }

  @include mixin.sliderLoader('[data-loader-slider]', 'dark');

  &__desc {
    margin-top: 0;

    @include rwd.breakpoint(mobileUp) {
      @include mixin.font(18px, 32px);
    }

    @include rwd.breakpoint(mobile) {
      @include mixin.font(16px, 24px);
    }

    @include rwd.breakpoint(tabletmobile) {
      margin-bottom: 20px;
    }

    @include rwd.breakpoint(smallUp) {
      margin-bottom: 40px;
    }
  }

  .splide {
    @include rwd.breakpoint(mobile) {
      width: calc(100% + 10px);
      margin-left: -5px;
    }

    @include rwd.breakpoint(mobileUp) {
      width: calc(100% + 20px);
      margin-left: -10px;
    }

    &:not(.is-initialized) {
      .info__list li {
        flex: 1;
      }
    }
  }

  .splide__arrow {
    top: 38% !important;
    transform: translateY(-50%) !important;

    &:before {
      color: var(--main-color) !important;
      transition: inherit;
    }

    &--next {
      right: -30px;
    }

    &--prev {
      left: -30px;
    }
  }

  .sectionTitle {
    @include rwd.breakpoint(phonesDown) {
      margin-bottom: 20px;
    }
  }

  .info {
    &__list {

      @include rwd.breakpoint(tabletmobile) {
        margin-left: 0;
        margin-right: 0;
      }

      @include rwd.breakpoint(smallPhones) {
        margin: 0 2px;
      }

      li {
        padding: 0 10px;

        @include rwd.breakpoint(smallUp) {
          padding: 0 10px;
        }

        @include rwd.breakpoint(tabletmobile) {
          margin-bottom: 20px;
        }

        @include rwd.breakpoint(smallPhones) {
          margin-bottom: 10px;
        }
      }

      &.infoSlider li {
        @include rwd.breakpoint(mobile) {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      @include mixin.flexValue(false, false, column);
      text-align: center;
      padding: 20px 20px 17px;
      height: 100%;
      background-color: $c-concrete-solid;
      text-decoration: none;
      transition: $transition;

      &:hover img {
        @include rwd.breakpoint(smallUp) {
          top: -10px;
        }
      }

      &:focus {
        @include rwd.breakpoint(smallUp) {
          border: 1px solid;
        }
      }

      @include rwd.breakpoint(small) {
        padding: 20px 14px;
      }

      @include rwd.breakpoint(mobile) {
        padding: 15px 10px;
      }

      @include rwd.breakpoint(smallPhones) {
        margin: 0 -3px;
      }

      img {
        @include mixin.pushAuto;
        @include mixin.position(relative, 0);
        width: 55%;
        height: 80px;
        transition: $transition;
        object-fit: contain;
        margin-bottom: 11px;

        @include rwd.breakpoint(smallPhones) {
          height: 62px;
          width: 75%;
        }
      }
    }

    &__textWrapper {
      @include mixin.flexCenter;
      margin: 0;
      -ms-flex: 1;
      flex: 1;
    }

    &__text {
      @include mixin.font(17px, 21px, 700);
      @include mixin.showLines(3);

      @include rwd.breakpoint(small) {
        @include mixin.font(15px, 24px);
      }

      @include rwd.breakpoint(mobile) {
        @include mixin.font(16px, 20px);
      }

      &.bold {
        font-weight: 700;
      }

      &.lead {
        @include mixin.font(16px, 24px, 400);
        margin-top: 8px;
      }
    }
  }

  .splide__pagination {
    @include rwd.breakpoint(tabletmobile) {
      margin-top: 0;
    }
  }

  svg {
    .st3 {
      fill: $c-abbey;
    }

    .st4 {
      fill: $c-abbey;
    }
  }

  @include rwd.breakpoint(small) {
    min-height: 250px;

    &.withLead {
      min-height: 374px;
    }
  }

  @include rwd.breakpoint(tablet) {
    min-height: 249px;

    &.withLead {
      min-height: 364px;
    }
  }

  @include rwd.breakpoint(largePhones) {
    min-height: 249px;

    &.withLead {
      min-height: 293px;
    }
  }

  @include rwd.breakpoint(mediumPhones) {
    min-height: 245px;

    &.withLead {
      min-height: 347px;
    }
  }

  @include rwd.breakpoint(smallPhones) {
    min-height: 241px;

    &.withLead {
      min-height: 329px;
    }
  }
}

.infoTiles {
  .splide:not(.is-initialized) .info__list li {
    flex: auto;
  }

  .smallBox .info__link {
    justify-content: space-around;

    img {
      width: 65%;
      height: 100px;
    }

    .info__text {
      align-self: flex-end;
    }
  }

  @include rwd.breakpoint(smallUp) {
    .info__list {
      @include mixin.flexValue(false, space-around);
      
      li {
        margin-bottom: 20px;
        width: 24%;
      }
    }

    .smallBox:not(.boxWithLead) .info__link {
      aspect-ratio: 1;
      padding: 80px 20px 44px;

      img {
        width: 65%;
        height: 120px;
      }
    }
  }

  @include rwd.breakpoint(small) {
    .smallBox .info__link {
      padding: 40px 20px 34px;

      img {
        width: 65%;
        height: 60px;
      }
    }
  }

  @include rwd.breakpoint(tabletmobile) {
    .container .col-4 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .info__list .col-4 {

      .info__link img {
        height: 40px;
        width: 75%;
      }
    }
  }

  @include rwd.breakpoint(smallPhones) {
    .info__list .col-4 .info__text {
      @include mixin.font(12px, 16px);
    }
  }
}